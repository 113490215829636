/* ==========================================================================
   Page
   ========================================================================== */

.c-page {

}

.c-page__header {
    margin-bottom: 10rem; // 100px

    h1 {
        margin-bottom: 2.5rem; // 25px
        @include fs--heading-2;
        color: $c-base__02;
    }

    p {
        @include fs--meta;
    }
}
.c-page__main {
    min-height: calc(100vh - 20rem);
}

form {
    margin-top: 6rem;
}

textarea {
    margin: 0rem -1rem 0rem -1rem;
    padding: 2rem 1rem 2rem 1rem;
    width: 100%;
    min-height: 30rem;
    line-height: 1.5;
    font-size: 1.8rem;
    border: None;
    resize: none;
    outline: none;
}

textarea[type='text']{
    font-family: 'Roboto Mono', monospace;
    font-weight: 300;
}

.form_feedback{
    visibility: hidden;
}

.form_feedback.animated{
    visibility: visible;
    -webkit-animation: fadeinout 3s linear forwards;
    animation: fadeinout 3s linear forwards;
}

@-webkit-keyframes fadeinout {
  0%,100% { opacity: 0; }
  40%, 60% { opacity: 1; }
}

@keyframes fadeinout {
  0%,100% { opacity: 0; }
  40%, 60% { opacity: 1; }
}

.c-page__footer {
    height: 2rem;
    margin-bottom: 4rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    p {
        @include fs--body;
        color: $c-base__01;
    }
}

