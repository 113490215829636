/* ==========================================================================
   Reset
   ========================================================================== */

/* Reset Modified from Normalize.css */

/* Base Reset */

* {
    margin: 0;
    padding: 0;
}

*, *:before, *:after { box-sizing: inherit; }


html {
    min-height: 100%;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    font-size: 55%; // Make it easy to calculate rems to px
}

body {
    @include ff--sans-serif(400);
    -webkit-font-smoothing: antialiased;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
    display: block;
}

header a {
  padding: 5px 1px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
    color: $c-base__02;
}


/* Media */
audio, canvas, progress, video {
    display: inline-block;
    vertical-align: baseline;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

/* Images */

[hidden], template { display: none; }

img {
    max-width: 100%;
    font-style: italic;
    vertical-align: middle;
    border: 0;
}

svg:not(:root) { overflow: hidden; }

svg {
    pointer-events: none;
    max-height: 100%
}

.center {
  text-align: center; 
}

/* Links */

a {
    color: $c-accent__blue;
    background-color: transparent;
    text-decoration: none;

    position: relative;
    display: inline-block;
    padding: 0px 1px;
    transition: color ease .1s;
    
    /* Hover animation effect for all buttons */
    &::after {
      content: '';
      position: absolute;
      z-index: -1;
      width: 0%;
      height: 100%;
      left: 0;
      bottom: 0;
      background-color: yellow;
      transition: all ease .1s;
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }

}

/* Override hover animation with no-hov class */
a.no-hov {
	&:after {
		content: none
	}
	
	&:hover {
		color: $c-accent__darkblue
	}
}

a.nav {
  padding: 10px 35px;  
  overflow:hidden;
}
a.nav:before {
    font-family: FontAwesome;
    content:"\f07a";
    position: absolute;
    top: 11px;
    left: -30px;
    transition: all 200ms ease;
}

abbr[title] { border-bottom: 1px dotted; }
b, strong   { font-weight: bold; }
i, em       { font-weight: italic; }

/* Content */

figure {
    margin: 0;
}


hr {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    width: 100%;
    height: 1px;
    border: 0;
    background: #EFF1F3;
}

/* Code Blocks */

pre { overflow: auto; }

code, pre {

}
small {
        color: gray;
}

